import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authorisation: {},
    user: null
  },
  getters: {
    getAuthorisation (state) {
      return state.authorisation;
    },
    getUser (state) {
      return state.user;
    }
  },
  mutations: {
    setAuthorisation (state, authorisation) {
      state.authorisation = authorisation;
    },
    setUser (state, user) {
      state.user = user;
    },
  },
  actions: {
  },
  modules: {
  }
})
