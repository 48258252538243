<template>
  <div>
    <b-row>
      <b-col class="my-3">
        <b-form-group
          label="姓名"
          class="float-left mr-3"
          autocomplete="off"
        >
          <b-form-input
            v-model="form.name"
            type="text"
            autocomplete="off"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Email"
          class="float-left mr-3"
        >
          <b-form-input
            v-model="form.email"
            type="email"
            autocomplete="off"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="密碼"
          class="float-left mr-3"
        >
          <b-form-input
            v-model="form.password"
            type="password"
            autocomplete="off"
          ></b-form-input>
        </b-form-group>

        <b-button
          variant="outline-primary"
          class="my-3 position-relative"
          style="top: 15px;"
          :disabled="!(Boolean(form.name) && Boolean(form.email) && Boolean(form.password))"
          @click="addUser()"
        >新增</b-button>
      </b-col>
    </b-row>

    <b-table
      :items="users"
      :fields="fields"
      label-sort-asc=""
      label-sort-desc=""
      label-sort-clear=""
      striped
      hover
      small
      class="table-light"
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(functions)="data">
        <b-button
          variant="outline-danger"
          class="my-3"
          size="sm"
          @click="deleteUser(data.item)"
          :disabled="data.item.id == 12"
        >刪除</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      users: [],
      fields: [
        { key: 'index', label: '#' },
        { key: 'name', label: '姓名', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'created_at', label: '產生時間', sortable: true },
        { key: 'updated_at', label: '更新時間', sortable: true },
        { key: 'functions', label: '功能' },
      ],
      form: {
        name: null,
        email: null,
        password: null
      }
    }
  },
  methods: {
    deleteUser (item) {
      if (confirm('確定刪除！')) {
        this.axios
          .delete(`/user/${item.id}`)
          .then(() => {
            const index = this.users.findIndex(element => element.id == item.id);
            this.users.splice(index, 1);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    addUser () {
      if (confirm('確定新增！')) {
        this.axios
          .post('/user', this.form)
          .then((response) => {
            this.users.push(response.data.data[0]);

            this.form.name = null;
            this.form.email = null;
            this.form.password = null;
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  },
  created () {
    this.axios
      .get('/user')
      .then(response => {
        this.users = response.data.data;
      })
      .catch(error => {
        console.log(error);
      })
  }
}
</script>

<style scoped>
.table >>> td {
  vertical-align: middle;
}
</style>
