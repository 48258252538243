<template>
  <div id="app">
    <b-container fluid>
      <b-row class="py-2 px-2">
        <b-col>
          <header class="mt-2 mb-3">
            <img src="./assets/logo.png" class="float-left m3-2" alt="優嘉珠心算">
            <h1>優嘉珠心算</h1>
            <h2>閃雷速心算系統</h2>
          </header>
        </b-col>
        <b-col class="text-right">
          <div v-if="$store.state.user">
            <span>{{ $store.state.user.name }}</span>
            <b-button
              variant="link"
              @click="logout()"
            >登出</b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <hr v-if="$route.path == '/login'">

    <b-navbar v-else type="dark" variant="dark" class="px-3">
      <b-navbar-nav>
        <b-nav-item to="/user" :active="$route.path == '/user'">使用者</b-nav-item>
        <b-nav-item to="/mental-arithmetic" :active="$route.path == '/mental-arithmetic'">心算</b-nav-item>
      </b-navbar-nav>
    </b-navbar>

    <b-container fluid>
      <b-row>
        <b-col>
          
          <router-view/>

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  methods: {
    logout () {
      const config = {
        headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
      };

      this.axios
        .post('/logout', {
            email: this.$store.state.user,
          },
          config)
        .then((response) => {
          if (response.data.status === 'success') {
            localStorage.removeItem('authorisation');
            localStorage.removeItem('user');

            this.$store.commit('setAuthorisation', {});
            this.$store.commit('setUser', null);
            
            this.$router.push({ path: '/login' });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
}
</script>

<style scoped>
header img {
  height: 65px;
  width: auto;
}
header h1 {
  font-size: 1.65rem;
}
header h2 {
  font-size: 1rem;
  font-weight: bold;
}
</style>
