import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import LoginView from '../views/LoginView.vue'
import UserView from '../views/UserView.vue'
import MentalArithmetic from '../views/MentalArithmetic.vue'

Vue.use(VueRouter)
Vue.use(VueAxios, axios)

const routes = [
  {
    path: '/login',
    name: '登入',
    component: LoginView
  },
  {
    path: '/user',
    name: '使用者',
    component: UserView
  },
  {
    path: '/mental-arithmetic',
    name: '心算',
    component: MentalArithmetic
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
