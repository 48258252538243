<template>
  <div>
    <b-form-row class="mt-2">
      <!-- <b-col cols="12" sm="6" md="6" lg="3" xl="3">
        <b-form-group
          label="幾目"
        >
          <b-input v-model.number="quantity" type="number" min="1" step="1"></b-input>
        </b-form-group>
      </b-col> -->
      <b-col cols="12" sm="6" md="6" lg="6" xl="6">
        <b-form-group
          label="位數"
        >
          <b-form-select v-model.number="digit">
            <b-form-select-option v-for="(item, index) in digits" :key="index" :value="item.value" :selected="item.value == 1">{{ item.text }}</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="6" xl="6">
        <b-form-group
          label="速度 (秒)"
        >
          <b-input v-model.number="speed" type="number" min="0.1" step="0.1"></b-input>
        </b-form-group>
      </b-col>
      <!-- <b-col cols="12" sm="6" md="6" lg="3" xl="3" style="margin-top: 25px;">
        <div v-for="(value, index) in ['+', '-', '*', '/']" :key="index" class="form-check form-check-inline">
          <input v-if="value === '+' || value === '-'" v-model="arithmetic" class="form-check-input" type="checkbox" :id="`fm-arithmetic-${index + 1}`" :value="value" :disabled="value == '+'">
          <input v-if="value === '*'" v-model="arithmetic" class="form-check-input" type="checkbox" :id="`fm-arithmetic-${index + 1}`" :value="value" :disabled="arithmetic.find(element => element === '/')">
          <input v-if="value === '/'" v-model="arithmetic" class="form-check-input" type="checkbox" :id="`fm-arithmetic-${index + 1}`" :value="value" :disabled="arithmetic.find(element => element === '*')">
          
          <label class="form-check-label" :for="`fm-arithmetic-${index + 1}`">{{ value }}</label>
        </div>

        <div class="form-check form-switch">
          <input v-model="sound" class="form-check-input" type="checkbox" id="fm-sound" disabled>
          <label class="form-check-label" for="fm-sound">聲音 <b>({{ sound ? '開啟' : '關閉' }})</b> <span class="text-danger">未完成</span></label>
        </div>      
      </b-col> -->
    </b-form-row>
    <b-form-row class="mt-2">
      <b-col cols="12" sm="6" md="6" lg="3" xl="3">
        <b-form-group
          label="加 +"
        >
          <b-input v-model.number="quantity.plus" type="number" min="0" step="1"></b-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="3" xl="3">
        <b-form-group
          label="減 -"
        >
          <b-input v-model.number="quantity.minus" type="number" min="0" step="1"></b-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="3" xl="3">
        <b-form-group
          label="乘 *"
        >
          <b-input v-model.number="quantity.times" type="number" min="0" step="1"></b-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="3" xl="3">
        <b-form-group
          label="除 ÷"
        >
          <b-input v-model.number="quantity.divided" type="number" min="0" step="1"></b-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <hr>

    <div class="row">
      <main class="col-12 text-center">
        <b-button
          v-if="topics.length === 0 || end"
          pill
          variant="primary"
          size="lg"
          @click="setTopics"
          :disabled="!(quantity.plus || quantity.minus || quantity.times || quantity.divided)"
        >開始</b-button>

        <!-- + 符號不顯示 -->
        
        <!-- <h1 v-else-if="topic">{{ topic.replace(/\+/, '') }}</h1> -->
        <transition v-else-if="topic" name="zoom">
          <h1 v-if="topicShow" :style="`--animation-duration: ${speed}s`">{{ topic.replace(/\+/, '') }}</h1>
        </transition>
        <h1 v-else class="text-success">{{ start }}</h1>
        <!-- <h1 v-else class="text-success wow fadeIn" :data-wow-delay="${speed}s">{{ start }}</h1> -->


      </main>
    </div>

    <div class="row">
      <div v-if="end === true" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
        <b-form-group
          label="請做答"
        >
          <b-input
            v-model.number="answer"
            type="number"
            min="1"
            autocomplete="off"
          ></b-input>
        </b-form-group>
      </div>
      <div v-if="end" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
        <label for="ft-show-topics" class="form-label">題目</label>
        <p>
          <span v-if="showTopic">{{ `${topics.join('')}=` }}</span>
          <span v-if="showTopic && showAnswer">{{ sum }}</span>
        </p>

        <div v-if="end" class="form-check form-switch">
          <input v-model="showTopic" class="form-check-input" type="checkbox" id="fm-show-topic" @change="showTopic == false ? showAnswer = false : null">
          <label class="form-check-label" for="fm-show-answer">題目{{ showTopic ? '顯示' : '隱藏' }}</label>
        </div>
        <div v-if="end" class="form-check form-switch">
          <input v-model="showAnswer" class="form-check-input" type="checkbox" id="fm-show-answer" :disabled="!showTopic">
          <label class="form-check-label" for="fm-show-answer">答案{{ showAnswer ? '顯示' : '隱藏' }}</label>
        </div>
      </div>
    </div>

    <div v-if="(answer && sum) && answer.toString().length >= sum.toString().length" class="row mt-4">
      <div class="col-12">
        <div v-if="answer == sum" class="alert alert-success" role="alert">正確</div>
        <div v-else class="alert alert-danger" role="alert">錯誤</div>
      </div>
    </div>
  </div>
</template>

<script>
import '@asika32764/vue-animate/dist/vue-animate.css';

export default {
  data () {
    return {
      // quantity: 5,        // 幾目
      digit: 99,          // 位數
      digits: [
        { value: 9, text: '1 (個)'},
        { value: 99, text: '2 (十)'},
        { value: 999, text: '3 (百)'},
        { value: 9999, text: '4 (千)'},
        { value: 99999, text: '5 (萬)'},
        { value: 999999, text: '6 (十萬)'},
        { value: 9999999, text: '7 (百萬)'},
        { value: 99999999, text: '8 (千萬)'}
      ],
      speed: 0.5,         // 速度
      // arithmetic: ['+'],  // 四則運算
      // setArithmetic: [],
      sound: false,       // 聲音

      // 幾目
      quantity: {
        plus: 0,            // 加 +
        minus: 0,           // 減 -
        times: 0,           // 乘 *
        divided: 0          // 除 ÷
      },
      topic: null,
      topicShow: false,
      topics: [],         // 題目
      answer: null,
      intervalID: null,
      start: null,
      end: false,
      sum: null,
      showTopic: false,
      showAnswer: false
    }
  },
  methods: {
    setTopics () {
      let tmp = 0;
      let time = 0;
      // let sum = 0;

      this.topic = null;
      this.topics.splice(0);
      this.start = null;
      this.end = false;
      this.answer = null;
      // this.showAnswer = true;

      for (let i = 0; i < this.quantity.plus; i++) {
        tmp = this.getRangeRandom(1, this.digit);

        if (i === 0) {
          this.topics.push(`${tmp}`);
        } else {
          tmp = `+${tmp}`;

          // if (/-/.test(tmp)) {
          //   sum = this.getRangeRandom(1, eval(this.topics.join('')) - 10);
          //   tmp = `${this.arithmetic[this.getRangeRandom(1, this.arithmetic.length) - 1]}${sum}`;
          // }

          this.topics.push(tmp);
        }
      }

      for (let i = 0; i < this.quantity.minus; i++) {
        tmp = this.getRangeRandom(1, this.digit);

        if (this.quantity.plus.length && i === 0) {
          this.topics.push(`${tmp}`);
        } else {
          tmp = `-${tmp}`;
          this.topics.push(tmp);
        }
      }

      for (let i = 0; i < this.quantity.times; i++) {
        tmp = this.getRangeRandom(1, this.digit);

        if (this.quantity.minus.length && i === 0) {
          this.topics.push(`${tmp}`);
        } else {
          tmp = `*${tmp}`;
          this.topics.push(tmp);
        }
      }

      for (let i = 0; i < this.quantity.divided; i++) {
        tmp = this.getRangeRandom(1, this.digit);

        if (this.quantity.times.length && i === 0) {
          this.topics.push(`${tmp}`);
        } else {
          tmp = `/${tmp}`;
          this.topics.push(tmp);
        }
      }


      for (let i = 0; i < this.topics.length; i++) {
        if (i === 0) {
          this.sum = this.topics[i];
        } else {
          this.sum = eval(`${this.sum}${this.topics[i]}`);
        }
      }

      // this.sum = eval(this.topics.join(''));

      tmp = [3, 2, 1, '開始'];
      for (let i = 0, len = tmp.length; i < len; i++) {
        setTimeout(() => {
          this.start = tmp[i];
        }, time = 1000 * i + 1);
      }

      for (let i = 0, len = this.topics.length; i < len; i++) {
        setTimeout(() => {
          this.topic = this.topics[i];
          this.topicShow = false;

          if (i === len - 1) {
            setTimeout(() => {

              this.end = true;
            }, this.speed * 1000);
          }
        }, time + (this.speed * 1000 * (i + 2)));

        setTimeout(() => {
          this.topicShow = true;     
        }, time + (this.speed * 1000 * (i + 2)));
      }
    },
    /**
     * 取得區間內的亂數
     * 
     * @param {number} min
     * @param {number} max
     * @return number
     */
    getRangeRandom(min, max){
      return Math.floor(Math.random() * max) + min;
    }
  }
}
</script>

<style scoped>
main h1 {
  font-size: 8rem;
}
</style>