import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL="https://api-you-jia-you-shu.footmark.com.tw/api";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

if (localStorage.getItem('authorisation')) {
  // JWT 超過時效性
  if ((Date.now() / 1000) >= JSON.parse(localStorage.getItem('authorisation')).expires_in) {
    localStorage.removeItem('authorisation');
    localStorage.removeItem('user');

    store.commit('setAuthorisation', {});
    store.commit('setUser', null);
} else {
    store.commit('setAuthorisation', JSON.parse(localStorage.getItem('authorisation')));
    store.commit('setUser', JSON.parse(localStorage.getItem('user')));
  }  
}

// https://stackoverflow.com/questions/43051291/attach-authorization-header-for-all-axios-requests#answer-43052288
axios.interceptors.request.use(function (config) {
  config.headers.Authorization = `${store.state.authorisation.type} ${store.state.authorisation.token}`;
  return config;
});

router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && !Object.keys(store.state.authorisation).length) {
    next({ path: '/login' });
  } else if (to.path === from.path) {
    next({ path: from.path });
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
